import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { clsx } from "clsx";
import { type ReactNode, useRef } from "react";
import { cn } from "~/hooks/use-tailwind";

interface TooltipComponentProps extends TooltipPrimitive.TooltipProps {
	children: ReactNode;
	content: ReactNode;
	triggerProps?: TooltipPrimitive.TooltipTriggerProps;
	contentProps?: TooltipPrimitive.TooltipContentProps;
	color?: "primary" | "gray";
}

export function Tooltip(props: TooltipComponentProps) {
	const { children, content, triggerProps, contentProps, color = "primary", ...restProps } = props;

	const { className: triggerClassName, ...restTriggerProps } = triggerProps || {};
	const {
		side = "bottom",
		sideOffset = 1,
		className: contentClassName,
		...restContentProps
	} = contentProps || {};

	const triggerRef = useRef<HTMLButtonElement>(null);

	return (
		<TooltipPrimitive.Provider delayDuration={0} {...restProps}>
			<TooltipPrimitive.Root>
				<TooltipPrimitive.Trigger
					ref={triggerRef}
					onClick={(event) => event.preventDefault()}
					className={cn("focus:outline-none", triggerClassName)}
					{...restTriggerProps}
				>
					{children}
				</TooltipPrimitive.Trigger>

				<TooltipPrimitive.Portal>
					<TooltipPrimitive.Content
						className={clsx(
							"z-2 max-w-72 rounded px-2 py-0.5",
							{
								"bg-primary-9 text-white": color === "primary",
								"bg-gray-9 text-white": color === "gray",
							},
							contentClassName,
						)}
						onPointerDownOutside={(event) => {
							if (event.target instanceof Element && triggerRef.current?.contains(event.target)) {
								event.preventDefault();
							}
						}}
						side={side}
						sideOffset={sideOffset}
						{...restContentProps}
					>
						{content}

						<TooltipPrimitive.Arrow
							className={clsx("fill-current", {
								"text-primary-9": color === "primary",
								"text-gray-9": color === "gray",
							})}
						/>
					</TooltipPrimitive.Content>
				</TooltipPrimitive.Portal>
			</TooltipPrimitive.Root>
		</TooltipPrimitive.Provider>
	);
}
