import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { Link, type LinkProps } from "@tanstack/react-router";
import { type ReactNode, useState } from "react";
import { Typography } from "~/components/utilities/Typography";
import { Anchor } from "~/components/utilities/interactive/Anchor";
import { cn } from "~/hooks/use-tailwind";
import { ActionButtonsRow, type ActionButtonsRowProps } from "~/layouts/utilities/Actions";

interface PageLayoutProps {
	title:
		| string
		| {
				component: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
				label: string;
		  };
	backLink?: Omit<LinkProps, "children"> & { children: ReactNode };
	children: ReactNode;
	className?: string;
	grow?: boolean;
	sidebar?: { variant: "fixed" | "collapsible"; children: ReactNode };
	actions?: ActionButtonsRowProps["items"];
	wrapperClassName?: string;
}

export function PageLayout(props: PageLayoutProps) {
	const {
		title,
		backLink,
		children,
		className,
		wrapperClassName,
		grow = true,
		sidebar,
		actions,
	} = props;
	const [openSidebar, setOpenSidebar] = useState<boolean>(sidebar?.variant === "fixed");

	return (
		<div
			className={cn(
				"background-floating-box mx-4 mb-4 flex grow flex-col [--sidebar-x:0rem] lg:[--sidebar-x:16rem] xl:[--sidebar-x:18rem] 2xl:[--sidebar-x:20rem]",
				{ grow: grow },
				wrapperClassName,
			)}
		>
			<div className="flex items-baseline gap-4 border-gray-3 border-b px-4 lg:gap-6">
				{backLink && (
					<Anchor startIcon={faArrowLeft} iconSize="sm" size="small" asChild>
						<Link {...backLink}>
							<span className="hidden sm:inline">{backLink.children}</span>
						</Link>
					</Anchor>
				)}

				{typeof title === "object" ? (
					<Typography
						weight="bold"
						size="lg"
						component={title.component}
						className="shrink-0 py-2 leading-[25px]"
					>
						{title.label}
					</Typography>
				) : (
					<Typography
						weight="bold"
						size="lg"
						component="h1"
						className="shrink-0 py-2 leading-[25px]"
					>
						{title}
					</Typography>
				)}

				<ActionButtonsRow
					open={openSidebar}
					setOpen={setOpenSidebar}
					variant={sidebar?.variant}
					items={actions}
				/>
			</div>

			<div className="flex grow flex-col overflow-hidden lg:flex-row">
				<div
					className={cn(
						"scrollbar-none m-4 flex max-h-[calc(100vh-var(--header-y)-var(--subheader-y)-var(--filters-y,0px)-44px)] grow flex-col overflow-y-auto safe-motion:transition-all will-change-transform",
						{ "lg:w-[calc(100%-16rem)]": openSidebar },
						className,
					)}
				>
					{children}
				</div>

				{sidebar && (
					<div
						className={cn(
							"flex shrink-0 flex-row flex-wrap items-start gap-6 border-gray-3 border-t p-4 safe-motion:transition-all will-change-transform *:min-w-[calc((100%-1.5rem)/2)] *:grow lg:w-[var(--sidebar-x)] lg:flex-col lg:border-t-0 lg:border-l lg:*:grow-0",
							{
								"-ml-[var(--sidebar-x)] translate-x-[var(--sidebar-x)]": !openSidebar,
								"ml-0 translate-x-0": openSidebar,
							},
						)}
					>
						{sidebar.children}
					</div>
				)}
			</div>
		</div>
	);
}
