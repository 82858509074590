import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "~/components/utilities/toast/use-toast";

export function useClipboard() {
	const { t } = useTranslation();
	const { toast } = useToast();

	const mutation = useMutation({
		mutationFn: async (newValue: string) => {
			const text = new ClipboardItem({
				"text/plain": new Blob([newValue], { type: "text/plain" }),
			});

			await navigator.clipboard.write([text]);

			return newValue;
		},
		onSuccess: (value) => toast({ title: `"${value}" ${t("copied_to_clipboard")}` }),
		onError: () => toast({ title: t("copied_to_clipboard_failed"), variant: "destructive" }),
	});

	return mutation;
}
