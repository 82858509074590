import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { $api } from "~/api";
import { catchNotFound } from "~/api/middleware/error-middleware";
import { TableCell } from "~/components/utilities/table/Cell";
import { useToast } from "~/components/utilities/toast/use-toast";
import { useCustomDocumentTitle } from "~/hooks/use-document-title";
import { useTableProps } from "~/hooks/use-table";
import { TableLayout } from "~/layouts/TableLayout";
import { SideBarBlock, SidebarDateItem, SidebarItem } from "~/layouts/utilities/Sidebar";
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from "~/types/route";

const productionBatchQueryOptions = (id: string) =>
	$api.queryOptions("get", "/api/v1/production-batches/{id}", {
		params: { path: { id } },
	});

export const Route = createFileRoute("/_authenticated/production-batches/$id")({
	component: RouteComponent,
	onError: catchNotFound,
	loader: ({ context, params }) =>
		context.queryClient.ensureQueryData(productionBatchQueryOptions(params.id)),
});

function RouteComponent() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { toast } = useToast();
	const params = Route.useParams();
	const productionBatchQuery = useSuspenseQuery(productionBatchQueryOptions(params.id));
	const productionBatch = productionBatchQuery.data;

	useCustomDocumentTitle(
		`${t("production_batch")} #${productionBatch.id} (${productionBatch.optimizations.length})`,
	);

	const columns = useMemo(() => {
		const columnHelper = createColumnHelper<(typeof productionBatch.optimizations)[number]>();

		return [
			columnHelper.accessor("id", {
				header: t("id"),
				cell: (info) => <TableCell variant="clipboard" value={info.getValue()} />,
			}),
			columnHelper.accessor("producible_type", {
				header: t("producible_type"),
				cell: (info) => <TableCell variant="default" value={info.getValue()} />,
			}),
			columnHelper.accessor("length", {
				header: t("length"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("width", {
				header: t("width"),
				cell: (info) => <TableCell variant="millimeter" value={info.getValue()} />,
			}),
			columnHelper.accessor("quantity", {
				header: t("quantity"),
				cell: (info) => <TableCell variant="default" value={info.getValue().toString()} />,
			}),
			columnHelper.accessor("optimized_at", {
				header: t("optimized_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="optimized_at" />,
			}),
			columnHelper.accessor("created_at", {
				header: t("created_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="created_at" />,
			}),
			columnHelper.accessor("updated_at", {
				header: t("updated_at"),
				cell: (info) => <TableCell variant="date" value={info.getValue()} props="updated_at" />,
			}),
		];
	}, [t]);

	const tableProps = useTableProps({
		id: "production-batch",
		pagination: false,
		rowSelection: false,
		columnVisibility: { id: false, created_at: false, updated_at: false },
	});

	const table = useReactTable({
		data: productionBatch.optimizations,
		columns,
		getRowId: (row) => row.id,
		getCoreRowModel: getCoreRowModel(),
		...tableProps.props,
	});

	const deleteBatch = $api.useMutation("delete", "/api/v1/production-batches/{public_id}", {
		onSuccess: async () => {
			toast({ title: t("production_batch_deleted") });
			await navigate({
				to: "/production-batches",
				search: { page: DEFAULT_PAGE_INDEX, per_page: DEFAULT_PAGE_SIZE },
			});
		},
	});

	return (
		<TableLayout
			tableProps={{
				table,
				fallback: t("no_optimizations_found"),
				rowLink: { to: "/production-batches/$id" },
			}}
			topbarProps={{
				title: `${t("production_batch")} #${productionBatch.id} (${productionBatch.optimizations.length})`,
				modules: { pagination: false, rowSelection: false, columnVisibility: true },
				backLink: { to: "/production-batches", children: t("production_batches") },
			}}
			actions={[
				{
					id: "delete",
					icon: faTrash,
					tooltip: t("delete_production_batch"),
					onClick: () =>
						deleteBatch.mutateAsync({ params: { path: { public_id: productionBatch.id } } }),
					withAffirmation: {
						title: t("are_you_sure_you_want_to_delete_this_production_batch"),
						description: t("this_action_cannot_be_undone"),
						cancel: t("no_dont_delete"),
						confirm: t("yes_delete"),
					},
				},
			]}
			sidebar={{
				variant: "fixed",
				children: (
					<>
						<SideBarBlock title={t("information")}>
							<SidebarItem label={t("comment")} value={productionBatch.comment} />
						</SideBarBlock>

						<SideBarBlock title={t("additional_information")}>
							<SidebarDateItem variant="production_date" value={productionBatch.production_date} />
							<SidebarDateItem variant="created_at" value={productionBatch.created_at} />
							<SidebarDateItem variant="updated_at" value={productionBatch.updated_at} />
						</SideBarBlock>
					</>
				),
			}}
		/>
	);
}
